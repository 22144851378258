import { DeserializableModel } from '@shared/models/deserializable.model';
import { MailLogStatus } from '@shared/enums/mail-log-status.enum';
import { User } from './user.model';
import { MailTemplate } from './mail-template.model';
import { Purchase } from './purchase.model';

export class MailLog extends DeserializableModel {
  mailTemplate: MailTemplate | null = null;
  subject!: string;
  body!: string;
  status!: MailLogStatus;
  userId!: number;
  trying!: number;
  sent!: Date | null;
  user!: User;
  purchase: Purchase | null = null; // A MailLog can have one Purchase

  deserialize(input: MailLog): this {
    Object.assign(this, input);
    this.mailTemplate = input.mailTemplate ? new MailTemplate().deserialize(input.mailTemplate) : null;
    this.purchase = input.purchase ? new Purchase().deserialize(input.purchase) : null;
    return this;
  }
}
