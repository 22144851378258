import { DeserializableModel } from '@shared/models/deserializable.model';
import {UserRole} from "@shared/interfaces/user-role.interface";
import { Partner } from './partner.model';
import { Language } from '../interfaces/languages.interface';
import { MailLog } from './mail-log.model';
import { Purchase } from './purchase.model';

export class User extends DeserializableModel {
  email!: string;
  fırstName!: string;
  lastName!: string;
  customerNumber!: string;
  accountName!: string;
  companyName!: string;
  userName!: string;
  ustId!: string;
  partner: Partner | null = null;
  defaultLang!: Language;
  lastLogin!: Date | null;
  mailLogs: MailLog[] = [];
  purchases: Purchase[] = [];
  role!: UserRole;
  isPartner!: boolean;

  deserialize(input: User): this {
    Object.assign(this, input);
    this.partner = input.partner ? new Partner().deserialize(input.partner) : null;
    this.mailLogs = input.mailLogs ? input.mailLogs.map((log: MailLog) => new MailLog().deserialize(log)) : [];
    this.purchases = input.purchases ? input.purchases.map((purchase: Purchase) => new Purchase().deserialize(purchase)) : [];
    return this;
  }
}
